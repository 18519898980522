import { leadApi } from 'common/http'
import { makeAutoObservable } from 'mobx'

class LeadStore {

    counter = 0

    // leadCount = 0

    count = { 
        all: 0,
        new: 0,
        liked: 0,
        watched: 0,
    }
    
    
    page = 1
    pages = null
    // limit = 10
    // sort = true
    status = 1
    season = 3

    defaultAges = {
        minAge: 18,
        maxAge: 100
    }

    minAge = this.defaultAges.minAge
    maxAge = this.defaultAges.maxAge

    leads = null

    loading = false
    error = ''


    constructor() {
        // this.page = +(new URLSearchParams(window.location.search).get('page')) ?? 1
        // params.get('page')
        this.limit = JSON.parse(localStorage.getItem('bc_limit')) ?? 10
        this.sort = JSON.parse(localStorage.getItem('bc_sort')) ?? true
        makeAutoObservable(this)
    }

    *postLead(data) {
        this.loading = true
        const response = yield leadApi.postLead(data)

        if (response.status !== 200) {
            this.handleError(response.message)
            return
        }

        this.loading = false
    }

    *getLeadCount() {
        // this.loading = true
        const response = yield leadApi.getCount(this.season, this.minAge, this.maxAge)

        if (response.status !== 200) {
            this.handleError(response.message)
            return
        }

        this.count = response.data.data
        // this.loading = false
    }

    *getLeads(all) {
        this.loading = true
        const response = all
            ? yield leadApi.getLeads(1, 9999, this.sort)
            : yield leadApi.getLeads(this.page, this.limit, this.sort, this.status, this.season, this.minAge, this.maxAge)

        if (response.status !== 200) {
            this.handleError(response.message)
            return
        }

        if (this.page === 1 && this.sort === false && this.status === 0) {
            this.resetCounter()
        }

        this.pages = (Math.ceil(response.data.count / this.limit))
        this.leads = response.data.data.map((lead) => {
            return { ...lead, createdAt: new Date(lead.createdAt).toLocaleString('ru-Ru', {timeZone: 'Europe/Moscow'}) }
        })

        this.loading = false
    }

    *setWatched(id, watched) {
        this.loading = true
        const response = yield leadApi.setWatched(id, watched)

        if (response.status !== 200) {
            this.handleError(response.message)
            return
        }

        // this.leads = this.leads.map((lead) => {
        //     if (lead.id === id) {
        //         return { ...lead, watched }
        //     }
        //     return { ...lead }
        // })
        this.loading = false
    }

    *setLiked(id, liked) {
        this.loading = true
        const response = yield leadApi.setLiked(id, liked)
        
        if (response.status !== 200) {
            this.handleError(response.message)
            return
        }

        // this.leads = this.leads.map((lead) => {
        //     if (lead.id === id) {
        //         return { ...lead, liked }
        //     }
        //     return { ...lead }
        // })
        this.loading = false
    }

    updateLiked(id, liked) {
        this.leads = this.leads.map((lead) => {
            if (lead.id === id) {
                return { ...lead, liked }
            }
            return { ...lead }
        })
    }

    updateWatched(id, watched) {
        this.leads = this.leads.map((lead) => {
            if (lead.id === id) {
                return { ...lead, watched }
            }
            return { ...lead }
        })
    }

    increaseCounter() {
        this.counter += 1
        this.leadCount += this.counter
    }

    resetCounter() {
        this.counter = 0
    }

    setPage(page) {
        this.page = page
    }

    setLimit(limit) {
        this.limit = limit
        localStorage.setItem('bc_limit', JSON.stringify(limit))
    }

    setSort(sort) {
        this.sort = sort
        localStorage.setItem('bc_sort', JSON.stringify(sort))
    }

    setStatus(status) {
        this.status = status
    }

    setSeason(season) {
        this.season = season
    }

    setAgeFilter(minAge, maxAge) {
        this.minAge = minAge
        this.maxAge = maxAge
    }

    resetAgeFilter() {
        this.minAge = this.defaultAges.minAge
        this.maxAge = this.defaultAges.maxAge
    }

    get isDefaultAge() {
        return this.minAge === this.defaultAges.minAge && this.maxAge === this.defaultAges.maxAge
    }

    // error setter
    setError(error) {
        this.error = error
    }

    ///////////////////////////////////////////////////
    // common handlers //

    // error handler //

    handleError(message) {
        this.error = message
        this.loading = false
    }

}

export default LeadStore