import { Block, Button, Description, Title, SocialIcon, Emoji } from 'common/components'

export const ModalPromotionalOver = ({ onClose }) => {
    return (
        <Block close={onClose}>
            <Title small>Спасибо за интерес, но, к сожалению, конкурс уже завершен {`:(`}</Title>
            <Description>Следите за нами в социальных сетях: город большой <Emoji /></Description>

            <div style={{ display: 'flex', gap: 10 }}>
                <a href="https://vk.com/ddx_fitness" rel="noreferrer" target="_blank">
                    <Button social>
                        <SocialIcon icon='vk' />
                    </Button>
                </a>
                <a href="https://t.me/ddxfitness_official" rel="noreferrer" target="_blank">
                    <Button social>
                        <SocialIcon icon='tg' />
                    </Button>
                </a>
                <a href="https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg" rel="noreferrer" target="_blank">
                    <Button social>
                        <SocialIcon icon='yt' />
                    </Button>
                </a>
                <a href="https://dzen.ru/ddx_fitness_ru" rel="noreferrer" target="_blank">
                    <Button social>
                        <SocialIcon icon='dzen' />
                    </Button>
                </a>
            </div>
            
        </Block>
    )
}