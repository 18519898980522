import { Pagination, Table } from 'common/components'
import { API_STREAM } from 'common/http/config'
import { AppContext } from 'index'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useRef } from 'react'
import style from './Leads.module.css'
import { LeadsSort } from './LeadsSort'
import { ScrollTop } from './ScrollTop'

const SHOW_DATA_USERS = {
    createdAt: 'Дата',
    name: 'ФИО',
    age: 'Возраст',
    phone: 'Телефон',
    email: 'E-mail',
    club: 'Клуб',
    // university: 'ВУЗ',
    // photoSession: 'Вид съемки',
    comment: 'Комментарий',
}

export const Leads = observer(({ openSelectAge }) => {
    const ssEvents = useRef(null)
    const { leadStore, appStore, authStore } = useContext(AppContext)

    useEffect(() => {
        leadStore.getLeads()
        appStore.toggleScroll()
    }, [
        leadStore.page,
        leadStore.limit,
        leadStore.sort,
        leadStore.status,
        leadStore.season,
        leadStore.minAge,
        leadStore.maxAge
    ])

    useEffect(() => {
        leadStore.getLeadCount()
    }, [leadStore.leads]) // eslint-disable-line

    useEffect(() => {

        ssEvents.current = new EventSource(API_STREAM, { withCredentials: true })
        
        ssEvents.current.onopen = (e) => {
            console.warn('sse opened')
        }
        // listen to error event
        ssEvents.current.onerror = (e) => { 
            console.warn('sse error')
        }

        ssEvents.current.addEventListener('lead_liked', (e) => {
            const { id, liked } = JSON.parse(e.data)
            leadStore.updateLiked(+id, liked)
        })

        ssEvents.current.addEventListener('lead_watched', (e) => {
            const { id, watched } = JSON.parse(e.data)
            leadStore.updateWatched(+id, watched)
        })

        ssEvents.current.addEventListener('lead_created', (e) => {
            leadStore.increaseCounter()
            appStore.setAlert(`Новые заявки (${leadStore.counter})`)
        })

        return () => {
            ssEvents.current.close()
        }

    }, [authStore.isAuth]) // eslint-disable-line

    return (
        <div className={style.leads}>

            {/* lead sort panel */}
            <LeadsSort
                sort={leadStore.sort} setSort={(sort) => { leadStore.setSort(sort); leadStore.setPage(1); }}
                limit={leadStore.limit} setLimit={(limit) => { leadStore.setLimit(limit); leadStore.setPage(1); }}
                status={leadStore.status} setStatus={(status) => { leadStore.setStatus(status); leadStore.setPage(1); }}
                openSelectAge={openSelectAge}
            />

            {/* leads table */}
            {leadStore.leads ?
                <Table
                    data={leadStore.leads}
                    showData={SHOW_DATA_USERS}
                    onLike={(id, liked) => leadStore.setLiked(id, liked)}
                    onWatch={(id, watched) => leadStore.setWatched(id, watched)}
                /> : 
                <Table mockup={leadStore.limit} showData={SHOW_DATA_USERS} />
            }

            {/* lead page pagination */}
            {leadStore.pages > 1 &&
                <div className={style.leads_pagination}>
                    <Pagination page={leadStore.page} setPage={(page) => leadStore.setPage(page)} pages={leadStore.pages} />
                </div>}

            {/* scroll top button */}
            {leadStore.leads?.length ? <ScrollTop onClick={() => appStore.toggleScroll()} /> : null}

        </div>
    )
})
